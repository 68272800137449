/* eslint-disable */
import React from "react";
import Slider from "react-slick";
import GalleryModal from '../components/gallery_modal';
import 'react-image-lightbox/style.css';

import "slick-carousel/slick/slick.css";

class ThreeImagesCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: null,
      initialImage: null,
      showGallery: false,
      isOpen: false,
      images: [],
      photoIndex:0
    }

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal(index) {
    this.setState({
      showGallery: true,
      initialImage: index
    });
  }

  handleCloseModal() {
    this.setState({
      showGallery: false,
      initialImage: null,
      isOpen: false,
      currentImage: null
    });
  }



  render() {

    var settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      focusOnSelect: true,
      centerMode: false,
      draggable: true,
      autoplay:true,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    let images = []
    var gal = this.props.data.relationships.field_slide_item

    gal.forEach(gallery => {
      images.push({
        url: gallery.localFile.childImageSharp.sizes.src
      });
    })
    return (

      <div class="tall-slider">
        <Slider {...settings}>
          {gal.map((gallery, index) => {
            return(
              <div key={index} onClick={() => this.handleOpenModal(index)} className="tall-carousel-img">
                  <img src={gallery.localFile.childImageSharp.sizes.src} alt="Scotties tiled image" />
              </div>
            )
          })}
        </Slider>
        {this.state.showGallery ?
          <GalleryModal
            images={images}
            showModal={this.state.showGallery}
            initialImage={this.state.initialImage}
            closeModal={this.handleCloseModal}
          />
        :
          null
        }
      </div>
    );

  }
}

export default ThreeImagesCarousel
