/* eslint-disable */
import React from 'react';
import Img from "gatsby-image";
import { Link } from "gatsby";
import sendEvent from '../helpers/sendAnalytics';

import Sep from '../assets/images/separator.svg'

class Separator extends React.Component {
  render() {
    return (
      <div className="separator">
        <img src={Sep} />
      </div>
    )
  }
}

export default Separator
