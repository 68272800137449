/* eslint-disable */
import React from 'react';
import GalleryModal from '../components/gallery_modal';

class BlogImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: null,
      initialImage: null,
      showGallery: false,
      isOpen: false,
    }

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal(index) {
    this.setState({
      showGallery: true,
      initialImage: index
    });
  }


  handleCloseModal() {
    this.setState({
      showGallery: false,
      initialImage: null,
      isOpen: false,
      currentImage: null
    });
  }

  render() {
    return (
      <div>
        <div className="full-width-image field--name-field-full-width-image" onClick={() => this.handleOpenModal(0)}>
          { (this.props.data.relationships.field_blog_image && this.props.data.relationships.field_blog_image.localFile) ?
              <img className="test" src={this.props.data.relationships.field_blog_image.localFile.childImageSharp.sizes.src} className={`float-${this.props.data.field_blog_float}`} style={{float: this.props.data.field_blog_float}} alt="" />
            : null }
        </div>

        {this.state.showGallery ? <GalleryModal
          images={[
            {
              url: this.props.data.relationships.field_blog_image.localFile.childImageSharp.original.src,
              caption: this.props.data.field_blog_image_caption
            }
          ]}
          showModal={this.state.showGallery}
          initialImage={this.state.initialImage}
          closeModal={this.handleCloseModal}
        /> : null }
      </div>
    )
  }
}

export default BlogImage
