/* eslint-disable */
import React from 'react';
import GalleryModal from '../components/gallery_modal';

class BlogImagesTiled extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: null,
      initialImage: null,
      showGallery: false,
      isOpen: false,
    }

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal(index) {
    this.setState({
      showGallery: true,
      initialImage: index
    });
  }

  handleCloseModal() {
    this.setState({
      showGallery: false,
      initialImage: null,
      isOpen: false,
      currentImage: null
    });
  }

  render() {
    return (
      <div>

        <div className="blog-images-tiled">
          <div className="blog-images-tiled-left" onClick={() => this.handleOpenModal(0)}>
            { (this.props.data.relationships.field_tiled_left_image && this.props.data.relationships.field_tiled_left_image.localFile) ?
                <img src={this.props.data.relationships.field_tiled_left_image.localFile.childImageSharp.sizes.src} className={`float-${this.props.data.field_blog_float}`} style={{float: this.props.data.field_blog_float}} alt="" />
              : null }
              <div className="caption"></div>
          </div>
          <div className="blog-images-tiled-right" onClick={() => this.handleOpenModal(1)}>
            { (this.props.data.relationships.field_tiled_right_image && this.props.data.relationships.field_tiled_right_image.localFile) ?
                <img src={this.props.data.relationships.field_tiled_right_image.localFile.childImageSharp.sizes.src} className={`float-${this.props.data.field_blog_float}`} style={{float: this.props.data.field_blog_float}} alt="" />
              : null }
          </div>
        </div>

        {this.state.showGallery ?
          <GalleryModal
            images={[
              {
                url: this.props.data.relationships.field_tiled_left_image.localFile.childImageSharp.sizes.src,
                caption: this.props.data.field_left_image_caption
              },
              {
                url: this.props.data.relationships.field_tiled_right_image.localFile.childImageSharp.sizes.src,
                caption: this.props.data.field_right_image_caption
              }
            ]}
            showModal={this.state.showGallery}
            initialImage={this.state.initialImage}
            closeModal={this.handleCloseModal}
          />
        :
          null
        }

      </div>
    )
  }
}

export default BlogImagesTiled
