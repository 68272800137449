/* eslint-disable */
import React from 'react';
import sendEvent from '../helpers/sendAnalytics';

class BodyText extends React.Component {
  sendLink(event){
    sendEvent({
      ga: {
        category: 'BCH_Tracking',
        action: 'External_Link',
        label: event.target.href
      }
    })
  }

  render() {
    return (
      <div>
        {(this.props.isBlog) ? (
          <div className="body-text blog" onClick={(event) => { this.sendLink(event) }} dangerouslySetInnerHTML={{__html: this.props.data.field_body_text.value }}></div>
        ) :
          <div className="body-text" dangerouslySetInnerHTML={{__html: this.props.data.field_body_text.value }}></div>
        }

      </div>

    )
  }
}

export default BodyText
