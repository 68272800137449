/* eslint-disable */
import React from 'react';
import GalleryModal from '../components/gallery_modal';

class ThreeImagesTiled extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: null,
      initialImage: null,
      showGallery: false,
      isOpen: false,
    }

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal(index) {
    this.setState({
      showGallery: true,
      initialImage: index
    });
  }

  handleCloseModal() {
    this.setState({
      showGallery: false,
      initialImage: null,
      isOpen: false,
      currentImage: null
    });
  }

  render() {
    return (
      <div>
        <div className="three_tiled">
        <div className="tile-left">
          { (this.props.data.relationships.field_left_image && this.props.data.relationships.field_left_image.localFile) ?
              <img onClick={() => this.handleOpenModal(0)} src={this.props.data.relationships.field_left_image.localFile.childImageSharp.sizes.src} className="right-image posi-" alt="Scotties tiled right" />
            : null }
        </div>
          <div className="tile-right">
            { (this.props.data.relationships.field_right_image && this.props.data.relationships.field_right_image.localFile) ?
                <img onClick={() => this.handleOpenModal(1)} src={this.props.data.relationships.field_right_image.localFile.childImageSharp.sizes.src} className="left-image" alt="Scotties tiled left" />
              : null }
          </div>
          <div className={"tile " + "pos-" + this.props.data.field_long_image_position} >
            { (this.props.data.relationships.field_full_image && this.props.data.relationships.field_full_image.localFile) ?
                <img onClick={() => this.handleOpenModal(2)} src={this.props.data.relationships.field_full_image.localFile.childImageSharp.sizes.src} className={"long-image " + "pos-" + this.props.data.field_long_image_position} alt="Scotties tiled long" />
              : null }
          </div>
        </div>

        {this.state.showGallery ?
          <GalleryModal
            images={[
              {
                url: this.props.data.relationships.field_left_image.localFile.childImageSharp.sizes.src
              },
              {
                url: this.props.data.relationships.field_right_image.localFile.childImageSharp.sizes.src
              },
              {
                url: this.props.data.relationships.field_full_image.localFile.childImageSharp.sizes.src
              }
            ]}
            showModal={this.state.showGallery}
            initialImage={this.state.initialImage}
            closeModal={this.handleCloseModal}
          />
        :
          null
        }
      </div>
    )
  }
}

export default ThreeImagesTiled
