/* eslint-disable */
import React from 'react';
import Img from "gatsby-image";
import { Link } from "gatsby";
import sendEvent from '../helpers/sendAnalytics';


class PromotedArticles extends React.Component {
  constructor(props) {
    super(props)
  }

  sendAnalytics(title){
    sendEvent({
      ga: {
        category: 'BCH_Tracking',
        action: 'Keep_Exploring_Click',
        label: title
      }
    })
  }

  render() {

    return (
        <div class="bluesect">
            <div class="main-wrap">
                <h2>Keep exploring</h2>
                <p>Discover how to bring a little comfort to every room in your home with more budget-friendly tips and décor inspiration in the articles below.</p>
                <div class="flex-promoted">
                    {
                        this.props.articles.map((article, index) => {
                            if(article.id != this.props.currentId){
                                return (
                                    <div class="third">
                                        <Link onClick={() => this.sendAnalytics(article.title)} to={article.path.alias}>
                                            { article.relationships.field_image.localFile != null ?
                                                <img src={article.relationships.field_image.localFile.childImageSharp.sizes.src} alt="" />:null
                                            }
                                            <div class="blue-bottom">
                                                <p>{article.relationships.field_categories[0].name}</p>
                                                <h6>{article.title.substring(0, 50)}</h6>
                                            </div>
                                        </Link>

                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>

        </div>
    )
  }
}
export default PromotedArticles;
